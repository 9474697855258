import facebook from '../../assets/socialMediaIcons/facebook.jpeg';
import instagram from '../../assets/socialMediaIcons/instagram.jpeg';
import snapchat from '../../assets/socialMediaIcons/snapchat.jpeg';
import tiktok from '../../assets/socialMediaIcons/tiktok.jpeg';
import twitter from '../../assets/socialMediaIcons/twitter.jpeg';
import youtube from '../../assets/socialMediaIcons/youtube.png';
import {
    Page,
    NamedLink,
    NamedRedirect,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    OrderPanel,
    ExternalLink,
} from '../../components';
import config from '../../config';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import {
    manageDisableScrolling,
    isScrollingDisabled,
} from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import routeConfiguration from '../../routing/routeConfiguration';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import {
    ensureListing,
    ensureOwnListing,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import {
    createResourceLocatorString,
    findRouteByRouteName,
} from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import {
    LISTING_STATE_PENDING_APPROVAL,
    LISTING_STATE_CLOSED,
    propTypes,
} from '../../util/types';
import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    createSlug,
} from '../../util/urlHelpers';
import ActionBarMaybe from './ActionBarMaybe';
import {
    sendEnquiry,
    fetchTransactionLineItems,
    setInitialValues,
} from './ListingPage.duck';
import css from './ListingPage.module.css';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionGallery from './SectionGallery';
import SectionHeading from './SectionHeading';
import SectionMapMaybe from './SectionMapMaybe';
import SectionReviews from './SectionReviews';
import SectionRulesMaybe from './SectionRulesMaybe';
import {
    faFilm,
    faImage,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    array,
    arrayOf,
    bool,
    func,
    shape,
    string,
    oneOf,
    object,
} from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: `(${price.currency})`,
            priceTitle: `Unsupported currency (${price.currency})`,
        };
    }
    return {};
};

const categoryLabel = (categories, key) => {
    const cat = categories.find((c) => c.key === key);
    return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
    constructor(props) {
        super(props);
        const { enquiryModalOpenForListingId, params } = props;
        this.state = {
            pageClassNames: [],
            imageCarouselOpen: false,
            enquiryModalOpen: enquiryModalOpenForListingId === params.id,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onContactUser = this.onContactUser.bind(this);
        this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    }

    handleSubmit(values) {
        const {
            history,
            getListing,
            params,
            callSetInitialValues,
            onInitializeCardPaymentData,
        } = this.props;
        const listingId = new UUID(params.id);
        const listing = getListing(listingId);

        const {
            bookingDates,
            quantity: quantityRaw,
            deliveryMethod,
            ...otherOrderData
        } = values;
        const bookingDatesMaybe = bookingDates
            ? {
                  bookingDates: {
                      bookingStart: bookingDates.startDate,
                      bookingEnd: bookingDates.endDate,
                  },
              }
            : {};

        const initialValues = {
            listing,
            orderData: {
                ...bookingDatesMaybe,
                quantity: Number.parseInt(quantityRaw, 10),
                deliveryMethod,
                ...otherOrderData,
            },
            confirmPaymentError: null,
        };

        const saveToSessionStorage = !this.props.currentUser;

        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected orderData
        const { setInitialValues } = findRouteByRouteName(
            'CheckoutPage',
            routes,
        );

        callSetInitialValues(
            setInitialValues,
            initialValues,
            saveToSessionStorage,
        );

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();

        // Redirect to CheckoutPage
        history.push(
            createResourceLocatorString(
                'CheckoutPage',
                routes,
                {
                    id: listing.id.uuid,
                    slug: createSlug(listing.attributes.title),
                },
                {},
            ),
        );
    }

    onContactUser() {
        const { currentUser, history, callSetInitialValues, params, location } =
            this.props;

        if (!currentUser) {
            const state = {
                from: `${location.pathname}${location.search}${location.hash}`,
            };

            // We need to log in before showing the modal, but first we need to ensure
            // that modal does open when user is redirected back to this listingpage
            callSetInitialValues(setInitialValues, {
                enquiryModalOpenForListingId: params.id,
            });

            // signup and return back to listingPage.
            history.push(
                createResourceLocatorString(
                    'SignupPage',
                    routeConfiguration(),
                    {},
                    {},
                ),
                state,
            );
        } else {
            this.setState({ enquiryModalOpen: true });
        }
    }

    onSubmitEnquiry(values) {
        const { history, params, onSendEnquiry } = this.props;
        const routes = routeConfiguration();
        const listingId = new UUID(params.id);
        const { message } = values;

        onSendEnquiry(listingId, message.trim())
            .then((txId) => {
                this.setState({ enquiryModalOpen: false });

                // Redirect to OrderDetailsPage
                history.push(
                    createResourceLocatorString(
                        'OrderDetailsPage',
                        routes,
                        { id: txId.uuid },
                        {},
                    ),
                );
            })
            .catch(() => {
                // Ignore, error handling in duck file
            });
    }

    render() {
        const {
            unitType,
            isAuthenticated,
            currentUser,
            getListing,
            getOwnListing,
            intl,
            onManageDisableScrolling,
            params: rawParams,
            location,
            scrollingDisabled,
            showListingError,
            reviews,
            fetchReviewsError,
            sendEnquiryInProgress,
            sendEnquiryError,
            timeSlots,
            fetchTimeSlotsError,
            customConfig,
            onFetchTransactionLineItems,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
        } = this.props;

        const listingId = new UUID(rawParams.id);
        const isPendingApprovalVariant =
            rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
        const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
        const currentListing =
            isPendingApprovalVariant || isDraftVariant
                ? ensureOwnListing(getOwnListing(listingId))
                : ensureListing(getListing(listingId));

        const listingSlug =
            rawParams.slug || createSlug(currentListing.attributes.title || '');
        const params = { slug: listingSlug, ...rawParams };

        const listingType = isDraftVariant
            ? LISTING_PAGE_PARAM_TYPE_DRAFT
            : LISTING_PAGE_PARAM_TYPE_EDIT;
        const listingTab = isDraftVariant ? 'photos' : 'details';

        const isApproved =
            currentListing.id &&
            currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

        const pendingIsApproved = isPendingApprovalVariant && isApproved;

        // If a /pending-approval URL is shared, the UI requires
        // authentication and attempts to fetch the listing from own
        // listings. This will fail with 403 Forbidden if the author is
        // another user. We use this information to try to fetch the
        // public listing.
        const pendingOtherUsersListing =
            (isPendingApprovalVariant || isDraftVariant) &&
            showListingError &&
            showListingError.status === 403;
        const shouldShowPublicListingPage =
            pendingIsApproved || pendingOtherUsersListing;

        if (shouldShowPublicListingPage) {
            return (
                <NamedRedirect
                    name="ListingPage"
                    params={params}
                    search={location.search}
                />
            );
        }

        const {
            description = '',
            geolocation = null,
            price = null,
            title = '',
            publicData,
        } = currentListing.attributes;

        const richTitle = (
            <span>
                {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                    longWordClass: css.longWord,
                })}
            </span>
        );

        const bookingTitle = (
            <FormattedMessage
                id="ListingPage.bookingTitle"
                values={{ title: richTitle }}
            />
        );

        const topbar = <TopbarContainer />;

        if (showListingError && showListingError.status === 404) {
            // 404 listing not found

            return <NotFoundPage />;
        } else if (showListingError) {
            // Other error in fetching listing

            const errorTitle = intl.formatMessage({
                id: 'ListingPage.errorLoadingListingTitle',
            });

            return (
                <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
                    <LayoutSingleColumn className={css.pageRoot}>
                        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                        <LayoutWrapperMain>
                            <p className={css.errorText}>
                                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
                            </p>
                        </LayoutWrapperMain>
                        <LayoutWrapperFooter>
                            <Footer />
                        </LayoutWrapperFooter>
                    </LayoutSingleColumn>
                </Page>
            );
        } else if (!currentListing.id) {
            // Still loading the listing

            const loadingTitle = intl.formatMessage({
                id: 'ListingPage.loadingListingTitle',
            });

            return (
                <Page
                    title={loadingTitle}
                    scrollingDisabled={scrollingDisabled}>
                    <LayoutSingleColumn className={css.pageRoot}>
                        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                        <LayoutWrapperMain>
                            <p className={css.loadingText}>
                                <FormattedMessage id="ListingPage.loadingListingMessage" />
                            </p>
                        </LayoutWrapperMain>
                        <LayoutWrapperFooter>
                            <Footer />
                        </LayoutWrapperFooter>
                    </LayoutSingleColumn>
                </Page>
            );
        }

        // const handleViewPhotosClick = (e) => {
        //     // Stop event from bubbling up to prevent image click handler
        //     // trying to open the carousel as well.
        //     e.stopPropagation();
        //     this.setState({
        //         imageCarouselOpen: true,
        //     });
        // };
        const authorAvailable = currentListing && currentListing.author;
        const userAndListingAuthorAvailable = !!(
            currentUser && authorAvailable
        );
        const isOwnListing =
            userAndListingAuthorAvailable &&
            currentListing.author.id.uuid === currentUser.id.uuid;
        const showContactUser =
            authorAvailable && (!currentUser || (currentUser && !isOwnListing));

        const currentAuthor = authorAvailable ? currentListing.author : null;
        const ensuredAuthor = ensureUser(currentAuthor);

        // When user is banned or deleted the listing is also deleted.
        // Because listing can be never showed with banned or deleted user we don't have to provide
        // banned or deleted display names for the function
        const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

        const { formattedPrice, priceTitle } = priceData(price, intl);

        const handleOrderSubmit = (values) => {
            const isCurrentlyClosed =
                currentListing.attributes.state === LISTING_STATE_CLOSED;
            if (isOwnListing || isCurrentlyClosed) {
                window.scrollTo(0, 0);
            } else {
                this.handleSubmit(values);
            }
        };

        const listingImages = (listing, variantName) =>
            (listing.images || [])
                .map((image) => {
                    const variants = image.attributes.variants;
                    const variant = variants ? variants[variantName] : null;

                    // deprecated
                    // for backwards combatility only
                    const sizes = image.attributes.sizes;
                    const size = sizes
                        ? sizes.find((i) => i.name === variantName)
                        : null;

                    return variant || size;
                })
                .filter((variant) => variant != null);

        const facebookImages = listingImages(currentListing, 'facebook');
        const twitterImages = listingImages(currentListing, 'twitter');
        const schemaImages = listingImages(
            currentListing,
            `${config.listing.variantPrefix}-2x`,
        ).map((img) => img.url);
        const siteTitle = config.siteTitle;
        const schemaTitle = intl.formatMessage(
            { id: 'ListingPage.schemaTitle' },
            { title, price: formattedPrice, siteTitle },
        );
        // You could add reviews, sku, etc. into page schema
        // Read more about product schema
        // https://developers.google.com/search/docs/advanced/structured-data/product
        const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
        const brand = currentListing?.attributes?.publicData?.brand;
        const brandMaybe = brand
            ? { brand: { '@type': 'Brand', name: brand } }
            : {};
        const schemaPriceNumber = intl.formatNumber(
            convertMoneyToNumber(price),
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        );
        const currentStock =
            currentListing.currentStock?.attributes?.quantity || 0;
        const schemaAvailability =
            currentStock > 0
                ? 'https://schema.org/InStock'
                : 'https://schema.org/OutOfStock';

        const authorLink = (
            <NamedLink
                className={css.authorNameLink}
                name="ListingPage"
                params={params}
                to={{ hash: '#author' }}>
                {authorDisplayName}
            </NamedLink>
        );

        const amenityOptions = findOptionsForSelectFilter(
            'amenities',
            customConfig.filters,
        );
        const categoryOptions = findOptionsForSelectFilter(
            'category',
            customConfig.filters,
        );
        const category =
            publicData && publicData.category ? (
                <span>
                    {categoryLabel(categoryOptions, publicData.category)}
                    <span className={css.separator}>•</span>
                </span>
            ) : null;

        const autoAddHttps = (url) => {
            const baseURL = url.split('.')[0];
            return baseURL === 'http' || baseURL === 'https'
                ? url
                : `https://${url}`;
        };

        const renderSocialMediaURls = (_listing) => {
            const socialMediaList = [
                { iconSrc: youtube, key: 'youtube_url' },
                { iconSrc: twitter, key: 'twitter_url' },
                { iconSrc: instagram, key: 'instagram_url' },
                { iconSrc: tiktok, key: 'tiktok_url' },
                { iconSrc: facebook, key: 'facebook_url' },
                { iconSrc: snapchat, key: 'snapchat_url' },
            ];

            return React.Children.toArray(
                socialMediaList.map(({ key, iconSrc }) => {
                    const url = _listing.attributes.publicData[key];
                    const name = key.split('_')[0];
                    return url &&
                        currentListing.attributes.publicData.platform ===
                            key.split('_')[0] ? (
                        <ExternalLink
                            href={autoAddHttps(url)}
                            style={{ marginRight: '15px' }}>
                            <img
                                alt={`${name}-icon`}
                                src={iconSrc}
                                width="30px"
                                height="30px"
                            />
                        </ExternalLink>
                    ) : null;
                }),
            );
        };

        const listingLocation = currentListing.attributes.publicData?.location;
        const locationIcon = listingLocation ? (
            <div
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    opacity: '0.5',
                }}>
                <FontAwesomeIcon
                    size="sm"
                    style={{ marginRight: '5px' }}
                    icon={faLocationDot}
                />
                {listingLocation?.address}
            </div>
        ) : null;

        const media = currentListing.attributes.publicData.media;
        const mediaIcon = media ? (
            media === 'picture' ? (
                <FontAwesomeIcon
                    size="sm"
                    style={{ marginLeft: '5px', opacity: '0.5' }}
                    icon={faImage}
                />
            ) : (
                <FontAwesomeIcon
                    size="sm"
                    style={{ marginLeft: '5px', opacity: '0.5' }}
                    icon={faFilm}
                />
            )
        ) : null;

        return (
            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                author={authorDisplayName}
                contentType="website"
                description={description}
                facebookImages={facebookImages}
                twitterImages={twitterImages}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'Product',
                    description: description,
                    name: schemaTitle,
                    image: schemaImages,
                    ...brandMaybe,
                    offers: {
                        '@type': 'Offer',
                        url: productURL,
                        priceCurrency: price.currency,
                        price: schemaPriceNumber,
                        availability: schemaAvailability,
                    },
                }}>
                <LayoutSingleColumn className={css.pageRoot}>
                    <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <div className={css.contentWrapperForProductLayout}>
                            <div className={css.mainColumnForProductLayout}>
                                {currentListing.id ? (
                                    <ActionBarMaybe
                                        className={
                                            css.actionBarForProductLayout
                                        }
                                        isOwnListing={isOwnListing}
                                        listing={currentListing}
                                        editParams={{
                                            id: listingId.uuid,
                                            slug: listingSlug,
                                            type: listingType,
                                            tab: listingTab,
                                        }}
                                    />
                                ) : null}
                                <SectionGallery listing={currentListing} />

                                <div className={css.productMobileHeading}>
                                    <SectionHeading
                                        priceTitle={priceTitle}
                                        formattedPrice={formattedPrice}
                                        richTitle={richTitle}
                                        category={category}
                                        authorLink={authorLink}
                                        showContactUser={showContactUser}
                                        onContactUser={this.onContactUser}
                                        mediaIcon={mediaIcon}
                                    />
                                </div>
                                <div className={css.sectionDetails}>
                                    {renderSocialMediaURls(currentListing)}
                                    {locationIcon}
                                </div>

                                <SectionDescriptionMaybe
                                    description={description}
                                    listingTitle={richTitle}
                                />
                                <SectionDetailsMaybe
                                    publicData={publicData}
                                    customConfig={customConfig}
                                />
                                <SectionFeaturesMaybe
                                    extendedDataKey="amenities"
                                    options={amenityOptions}
                                    publicData={publicData}
                                />
                                <SectionRulesMaybe publicData={publicData} />
                                {/* <SectionMapMaybe
                                    geolocation={geolocation}
                                    publicData={publicData}
                                    listingId={currentListing.id}
                                /> */}
                                <SectionReviews
                                    reviews={reviews}
                                    fetchReviewsError={fetchReviewsError}
                                />
                                <SectionAuthorMaybe
                                    title={title}
                                    listing={currentListing}
                                    authorDisplayName={authorDisplayName}
                                    onContactUser={this.onContactUser}
                                    isEnquiryModalOpen={
                                        isAuthenticated &&
                                        this.state.enquiryModalOpen
                                    }
                                    onCloseEnquiryModal={() =>
                                        this.setState({
                                            enquiryModalOpen: false,
                                        })
                                    }
                                    sendEnquiryError={sendEnquiryError}
                                    sendEnquiryInProgress={
                                        sendEnquiryInProgress
                                    }
                                    onSubmitEnquiry={this.onSubmitEnquiry}
                                    currentUser={currentUser}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                />
                            </div>
                            <div className={css.orderColumnForProductLayout}>
                                <OrderPanel
                                    mediaIcon={mediaIcon}
                                    className={css.productOrderPanel}
                                    listing={currentListing}
                                    isOwnListing={isOwnListing}
                                    unitType={unitType}
                                    onSubmit={handleOrderSubmit}
                                    title={bookingTitle}
                                    author={ensuredAuthor}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    onContactUser={this.onContactUser}
                                    timeSlots={timeSlots}
                                    fetchTimeSlotsError={fetchTimeSlotsError}
                                    onFetchTransactionLineItems={
                                        onFetchTransactionLineItems
                                    }
                                    lineItems={lineItems}
                                    fetchLineItemsInProgress={
                                        fetchLineItemsInProgress
                                    }
                                    fetchLineItemsError={fetchLineItemsError}
                                />
                            </div>
                        </div>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }
}

ListingPageComponent.defaultProps = {
    unitType: config.lineItemUnitType,
    currentUser: null,
    enquiryModalOpenForListingId: null,
    showListingError: null,
    reviews: [],
    fetchReviewsError: null,
    timeSlots: null,
    fetchTimeSlotsError: null,
    sendEnquiryError: null,
    customConfig: config.custom,
    lineItems: null,
    fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    unitType: propTypes.lineItemUnitType,
    // from injectIntl
    intl: intlShape.isRequired,

    params: shape({
        id: string.isRequired,
        slug: string,
        variant: oneOf([
            LISTING_PAGE_DRAFT_VARIANT,
            LISTING_PAGE_PENDING_APPROVAL_VARIANT,
        ]),
    }).isRequired,

    isAuthenticated: bool.isRequired,
    currentUser: propTypes.currentUser,
    getListing: func.isRequired,
    getOwnListing: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    scrollingDisabled: bool.isRequired,
    enquiryModalOpenForListingId: string,
    showListingError: propTypes.error,
    callSetInitialValues: func.isRequired,
    reviews: arrayOf(propTypes.review),
    fetchReviewsError: propTypes.error,
    timeSlots: arrayOf(propTypes.timeSlot),
    fetchTimeSlotsError: propTypes.error,
    sendEnquiryInProgress: bool.isRequired,
    sendEnquiryError: propTypes.error,
    onSendEnquiry: func.isRequired,
    onInitializeCardPaymentData: func.isRequired,
    customConfig: object,
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,
};

const mapStateToProps = (state) => {
    const { isAuthenticated } = state.Auth;
    const {
        showListingError,
        reviews,
        fetchReviewsError,
        timeSlots,
        fetchTimeSlotsError,
        sendEnquiryInProgress,
        sendEnquiryError,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        enquiryModalOpenForListingId,
    } = state.ListingPage;
    const { currentUser } = state.user;

    const getListing = (id) => {
        const ref = { id, type: 'listing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    const getOwnListing = (id) => {
        const ref = { id, type: 'ownListing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    return {
        isAuthenticated,
        currentUser,
        getListing,
        getOwnListing,
        scrollingDisabled: isScrollingDisabled(state),
        enquiryModalOpenForListingId,
        showListingError,
        reviews,
        fetchReviewsError,
        timeSlots,
        fetchTimeSlotsError,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        sendEnquiryInProgress,
        sendEnquiryError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
        dispatch(setInitialValues(values, saveToSessionStorage)),
    onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
        dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
    onSendEnquiry: (listingId, message) =>
        dispatch(sendEnquiry(listingId, message)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(ListingPageComponent);

export default ListingPage;
