import {
    AspectRatioWrapper,
    Button,
    IconClose,
    IconArrowHead,
    ResponsiveImage,
} from '../../../components';
import {
    FormattedMessage,
    injectIntl,
    intlShape,
} from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import css from './ListingImageGallery.module.css';
// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactImageGallery from 'react-image-gallery';

const IMAGE_GALLERY_OPTIONS = {
    showPlayButton: false,
    disableThumbnailScroll: true,
};

const ListingImageGallery = (props) => {
    const {
        intl,
        rootClassName,
        className,
        images,
        imageVariants,
        thumbnailVariants,
    } = props;
    const thumbVariants = thumbnailVariants || imageVariants;
    const items = images.map((img, i) => {
        return {
            // We will only use the image resource, but react-image-gallery
            // requires the `original` key from each item.
            original: '',
            alt: intl.formatMessage(
                { id: 'ListingImageGallery.imageAltText' },
                { index: i + 1, count: images.length },
            ),
            thumbAlt: intl.formatMessage(
                { id: 'ListingImageGallery.imageThumbnailAltText' },
                { index: i + 1, count: images.length },
            ),
            image: img,
        };
    });
    const [isFullscreen, setIsFullscreen] = useState(false);
    const renderItem = (item) => {
        return (
            <AspectRatioWrapper
                width={1}
                height={1}
                className={
                    isFullscreen ? css.itemWrapperFullscreen : css.itemWrapper
                }>
                <div className={css.itemCentering}>
                    <ResponsiveImage
                        rootClassName={css.item}
                        image={item.image}
                        alt={item.alt}
                        variants={imageVariants}
                    />
                </div>
            </AspectRatioWrapper>
        );
    };
    const renderThumbInner = (item) => {
        return (
            <div>
                <ResponsiveImage
                    rootClassName={css.thumb}
                    image={item.image}
                    alt={item.thumbAlt}
                    variants={thumbVariants}
                />
            </div>
        );
    };

    const onScreenChange = (isFull) => {
        setIsFullscreen(isFull);
    };

    const renderLeftNav = (onClick, disabled) => {
        return (
            <button
                className={css.navLeft}
                disabled={disabled}
                onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead direction="left" size="big" />
                </div>
            </button>
        );
    };
    const renderRightNav = (onClick, disabled) => {
        return (
            <button
                className={css.navRight}
                disabled={disabled}
                onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead direction="right" size="big" />
                </div>
            </button>
        );
    };
    const renderFullscreenButton = (onClick, isFullscreen) => {
        return isFullscreen ? (
            <Button
                onClick={onClick}
                rootClassName={css.close}
                title={intl.formatMessage({
                    id: 'ListingImageGallery.closeModalTitle',
                })}>
                <span className={css.closeText}>
                    <FormattedMessage id="ListingImageGallery.closeModal" />
                </span>
                <IconClose rootClassName={css.closeIcon} />
            </Button>
        ) : (
            <button className={css.openFullscreen} onClick={onClick}>
                <FormattedMessage
                    id="ListingImageGallery.viewImagesButton"
                    values={{ count: images.length }}
                />
            </button>
        );
    };

    if (items.length === 0) {
        const classes = classNames(rootClassName || css.noImage, className);
        return (
            <ResponsiveImage
                className={css.noImage}
                image={null}
                variants={[]}
                alt=""
            />
        );
    }

    const classes = classNames(rootClassName || css.root, className);

    return (
        <ReactImageGallery
            additionalClass={classes}
            items={items}
            renderItem={renderItem}
            renderThumbInner={renderThumbInner}
            onScreenChange={onScreenChange}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderFullscreenButton={renderFullscreenButton}
            {...IMAGE_GALLERY_OPTIONS}
        />
    );
};

ListingImageGallery.defaultProps = {
    rootClassName: null,
    className: null,
    thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
    rootClassName: string,
    className: string,
    images: arrayOf(propTypes.image).isRequired,
    imageVariants: arrayOf(string).isRequired,
    thumbnailVariants: arrayOf(string),

    // from injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
